
import Customize from "../../sections/customize/index"
import Footer from "../../sections/footer";
import Header from "../../sections/header";

export default function CustomizeNets() {
  return (
    <>
    <Header />
    <Customize />
    <Footer />
    </>
  );
}
